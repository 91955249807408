import React from 'react';
import TeamItem from '../../../Components/TeamItem/TeamItem';
import { Container, Row, Col } from 'react-bootstrap';
import SectionTitle from '../../../Components/SectionTitle/SectionTitle';
import ShowMore from '../../../Components/ShowMore/ShowMore';
import { useHistory } from 'react-router-dom';
import Section from '../../../Components/Section/Section';
import Loading from '../../../Components/Loading/Loading';
import EmptyHolder from '../../../Components/EmptyHolder/EmptyHolder';
const teammembers = Array(4).fill(null);

const OurTeam = (props) => {
    let history = useHistory();
    const { data, loading } = props;

    const groupedTeams = (data || []).reduce((acc, team, index) => {
        const rowIndex = team?.row || 3;
        if (!acc[rowIndex]) {
            acc[rowIndex] = [];
        }
        acc[rowIndex].push(team);
        return acc;
    }, []);


    return (
        <Section title="Executive Member"
            description=" "
        // description="Our mission is to inspire and develop people to achieve their goals in STEM – one person, one question, and one small commitment to learning at a time."
        >
            <Container>
                <Row className="justify-content-center">

                    <Col lg={12}>
                        {groupedTeams.map((row, rowIndex) => (
                            <Row key={rowIndex} className="mb-4 justify-content-center">
                                <Col xs={6} lg={1}></Col>
                                {row.sort((a, b) => a.order - b.order).map((team) => {
                                    console.log(team, 'team')
                                    return (
                                        <Col key={team.id} lg={2} className="mb-4">
                                            <TeamItem data={team} />
                                        </Col>
                                    )
                                })}
                            </Row>
                        ))}
                    </Col>
                    {loading && <Loading className="mb-5" />}
                    {!loading && data.length === 0 &&
                        <Col lg={12} className="d-flex justify-content-center">
                            <EmptyHolder text="Team Not Found" />
                        </Col>
                    }
                </Row>
                {data && data.length > 4 &&
                    <ShowMore
                        onClick={() => {
                            history.push('/team')
                        }}
                    />
                }

            </Container>
        </Section>
    )
}

export default OurTeam;