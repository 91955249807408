import React, { useEffect } from 'react';
import './App.scss';
import { Switch, Route } from 'react-router-dom';
import Home from '../views/Home/Home';
import About from '../views/About/About';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../redux/store';
import Layout from './Layout';
import { primaryMenu } from '../data/data';
import Admission from '../views/Admission/Admission';
import Course from '../views/Course/Course';
import Team from '../views/Team/Team';
import Contact from '../views/Contact/Contact';
import Event from '../views/Event/Event';
import { useSelector, useDispatch } from 'react-redux';
import Details from '../views/Course/Details/Details';
import Blogs from '../views/Blogs/Blogs';
import Blog from '../views/Blogs/Blog/Blog';
import { fetchBanners } from '../redux/actions/Banners';
import { fetchOrganizationDetails } from '../redux/actions/organizationInfo';
import Alumni from '../views/Alumni/Alumni';
import BOD from '../views/BOD/BOD';
import Messages from '../views/Messages/Messages';
import StudentVoice from '../views/StudentVoice/StudentVoice';
import ReactGA from 'react-ga';
import Downloads from '../views/Downloads/Downloads';
import Career from '../views/Career/Career';
import Page from '../views/Page/Page';
import ResearchActivity from '../views/ResearchActivities/ResearchActivity/ResearchActivities';

const App = () => {
  const route = useSelector(state => state.router);
  const dispatch = useDispatch()

  ReactGA.initialize('UA-179081378-1')

  useEffect(() => {
    dispatch(fetchOrganizationDetails())

  }, [0])
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [route])
  return (
    <Layout menuItems={primaryMenu} >
      <ConnectedRouter history={history}>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/about-us' exact component={About} />
          <Route path='/admission' exact component={Admission} />
          <Route path='/courses' exact component={Course} />
          <Route path='/course/:slug' exact component={Details} />
          <Route path='/team' exact component={Team} />
          <Route path='/contact-us' exact component={Contact} />
          <Route path='/events' exact component={Event} />
          <Route path='/news-events' exact component={Blogs} />
          <Route path='/news/:slug' exact component={Blog} />
          <Route path='/research-activities/:slug' exact component={ResearchActivity} />
          <Route path='/alumni' exact component={Alumni} />
          <Route path='/career' exact component={Career} />

          <Route path='/downloads' exact component={Downloads} />
          <Route path='/bod' exact component={BOD} />
          <Route path='/messages' exact component={Messages} />
          <Route path='/student-voice' exact component={StudentVoice} />
          <Route path='/page/:slug' exact component={Page} />
        </Switch>
      </ConnectedRouter>
    </Layout>
  );
}

export default App;
