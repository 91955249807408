import React, { useEffect, useState } from 'react';
import './Blogs.scss';
import { Container, Row, Col } from 'react-bootstrap';
import NewsItem from '../../Components/NewsItem/NewsItem';
import Section from '../../Components/Section/Section';
import Stories from '../../Components/Stories/Stories';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNews } from '../../redux/actions/News';
import { arrayChunk } from '../../utils/arrayChunk';
import Loading from '../../Components/Loading/Loading';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../api/api';

const NewsArticle = Array(4).fill(null);
const monologs = Array(3).fill(null);

const ResearchActivities = props => {
    const dispatch = useDispatch();
    const News = useSelector(state => state.News);
    const location = useLocation();
    const history = useHistory();

    // Get current page from URL or default to 1
    const searchParams = new URLSearchParams(location.search);
    const currentPage = parseInt(searchParams.get('page')) || 1;
    const pageSize = 4; // 4 news items per page (1 big + 3 small)
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        dispatch(fetchNews(`status=true&_limit=${pageSize}&_start=${(currentPage - 1) * pageSize}&status=true&type_eq=RESEARCH_ACTIVITIES&_sort=created_at:DESC`));
    }, [currentPage]);

    const handlePageChange = (newPage) => {
        history.push(`?page=${newPage}`);
    };
    console.log(totalPages, 'totalPages')

    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const fetchPublications = async () => {
            try {


                const responseCount = await axios.get(`${baseUrl}/blogs/count?status=true&type_eq=RESEARCH_ACTIVITIES`);
                console.log(responseCount.data, 'responseCount')
                setTotalCount(responseCount.data);
                setTotalPages(Math.ceil(responseCount.data / pageSize));
            } catch (err) {
                console.error('Error fetching publications:', err);
            } finally {
            }
        };

        fetchPublications();
    }, [currentPage]);

    const popNews = News.news ? arrayChunk(News.news, 4) : false;

    return (
        <React.Fragment>
            <Container>
                <Section title="Research Activities">
                    {!News.fetching && popNews && popNews.map((bulkNews, index) => (
                        <Row key={index} className="align-items-center mb-5">
                            <Col lg={5}>
                                <NewsItem data={bulkNews[0]} size="big" type="research-activities" />
                            </Col>
                            <Col lg={7} className="pl-3 pl-lg-5">
                                {bulkNews.length > 1 && bulkNews.slice(1, 4).map((news, index) => (
                                    <NewsItem key={index} data={news} type="research-activities" />
                                ))}
                            </Col>
                        </Row>
                    ))}

                    {totalPages > 1 && (
                        <div className="pagination">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="pagination-button"
                            >
                                Previous
                            </button>

                            {[...Array(totalPages)].map((_, index) => (
                                <button
                                    key={index + 1}
                                    onClick={() => handlePageChange(index + 1)}
                                    className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                                >
                                    {index + 1}
                                </button>
                            ))}

                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="pagination-button"
                            >
                                Next
                            </button>
                        </div>
                    )}

                    <Row className="my-5 justify-content-center">
                        {News.fetching && <Loading />}
                        {!News.fetching && News.news.length === 0 &&
                            <EmptyHolder text="News are Not Available" />
                        }
                    </Row>
                </Section>
            </Container>
        </React.Fragment>
    );
};

export default ResearchActivities;