import React, { useEffect } from 'react';
import './Team.scss';
import { Container, Row, Col } from 'react-bootstrap';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import TeamItem from '../../Components/TeamItem/TeamItem';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeams } from '../../redux/actions/Teams';
import Loading from '../../Components/Loading/Loading';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';


const Team = props => {
    const dispatch = useDispatch();
    const Teams = useSelector(state => state.Teams);
    useEffect(() => {
        dispatch(fetchTeams('status=true'));
    }, [0])

    // Group teams by row (5 items per row)
    const groupedTeams = (Teams.teams || []).reduce((acc, team, index) => {
        const rowIndex = team?.row || 3;
        if (!acc[rowIndex]) {
            acc[rowIndex] = [];
        }
        acc[rowIndex].push(team);
        return acc;
    }, []);


    return (
        <Container className="mb-5">
            {!Teams.fetching && Teams.teams.length > 0 && (
                <React.Fragment>
                    <SectionTitle text="Executive Member" description=" " />

                    {groupedTeams.map((row, rowIndex) => (
                        <Row key={rowIndex} className="mb-4 justify-content-center">
                            <Col xs={6} lg={1}></Col>
                            {row.sort((a, b) => a.order - b.order).map((team) => {
                                console.log(team, 'team')
                                return (
                                    <Col key={team.id} lg={2} className="mb-4">
                                        <TeamItem data={team} />
                                    </Col>
                                )
                            })}
                        </Row>
                    ))}
                </React.Fragment>
            )}

            <Row className="my-5 justify-content-center">
                {Teams.fetching && <Loading />}
                {!Teams.fetching && Teams.teams.length === 0 &&
                    <EmptyHolder text="Team informations Not Available" />
                }
            </Row>
        </Container>
    );
};

export default Team;