import React, { useEffect } from 'react';
import './Blog.scss';
import { Container, Row, Col } from 'react-bootstrap';
import Section from '../../../Components/Section/Section';
import Loading from '../../../Components/Loading/Loading';
import EmptyHolder from '../../../Components/EmptyHolder/EmptyHolder';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCourseDetails, fetchCourses } from '../../../redux/actions/Courses';
import { fetchNewsDetails, fetchNews } from '../../../redux/actions/News';
import { getImageUrl } from '../../../utils/ImageUrl';
import { useHistory } from 'react-router-dom';

const ResearchActivity = props => {
    const News = useSelector(state => state.News);
    const newsData = News.selectedNews;
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const history = useHistory();
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchNewsDetails(`status=true&slug_eq=${props.match.params.slug}`));
        dispatch(fetchNews(`status=true&_limit=2&slug_ne=${props.match.params.slug}&type_eq=RESEARCH_ACTIVITIES`))

    }, [props])

    const otherNews = (value) => {
        // window.scrollTo(0,0)
        // dispatch(fetchNewsDetails(`status=true&slug_eq=${value}`));
        // dispatch(fetchNews(`status=true&_limit=2&slug_ne=${value}`))
        history.push(`/news/${value}`)
    }
    return (
        <React.Fragment>
            <div className="blog-page">
                {!News.fetching && newsData &&
                    <Container className="blog-wrapper">
                        <Row className="mb-5">
                            <Col lg={12}>
                                <img style={{ width: '50%', float: 'left', marginRight: '55px', marginBottom: '15px' }} src={newsData.featuredImage ? getImageUrl(newsData.featuredImage.url) : 'http://placehold.jp/800x500.png'} alt="Happy Business" />

                                <h2 className="text-bolder">
                                    {newsData.title}
                                </h2>
                                <span className="text-date">{(new Date(newsData.created_at)).toLocaleDateString("en-US", options)}</span>
                                <p dangerouslySetInnerHTML={{ __html: newsData.excerpt || '' }}></p>
                                <p dangerouslySetInnerHTML={{ __html: newsData.content ? newsData.content : '' }}></p>

                            </Col>

                            {/* {newsData.alternateImage &&
                                <Col lg={4} className="mt-5">
                                    <img style={{ width: '100%' }} src={newsData.alternateImage ? getImageUrl(newsData.alternateImage.url) : 'http://placehold.jp/300x500.png'} alt="Waiter Display" />
                                </Col>
                            } */}

                            {/* <Col lg={newsData.alternateImage ? 8 : 12} className="mt-5">
                                <p dangerouslySetInnerHTML={{ __html: newsData.content ? newsData.content : '' }}></p>
                            </Col> */}
                        </Row>
                    </Container>
                }
                {!News.fetching && News.news.length > 0 &&
                    <Section background="gray pb-5">
                        <Container className="alternate-blog my-5">
                            <Row className="justify-content-between">
                                <Col lg={4}>
                                    <p className="text-uppercase indicator left">
                                        PREV ARTICLE
                                    </p>
                                    <h4 onClick={() => {
                                        otherNews(News.news[0] ? News.news[0].slug : '#')
                                    }} className="text-bolder">{News.news[0] ? News.news[0].title : ''}</h4>
                                </Col>
                                <Col lg={4}>
                                    <p className="text-uppercase indicator left text-right">
                                        Next ARTICLE
                                    </p>
                                    <h4 onClick={() => {
                                        history.push(`/news/${News.news[1] ? News.news[1].slug : '#'}`)
                                    }}
                                        className="text-bolder text-right">{News.news[1] ? News.news[1].title : ''}</h4>
                                </Col>
                            </Row>
                        </Container>
                    </Section>
                }

            </div>
        </React.Fragment>
    )
}

export default ResearchActivity;