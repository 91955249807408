import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Nav, Row, Col, Container, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Slider from '../../Components/Slider/Slider';
import EventSection from '../Dashboard/EventSection/EventSection';
import Features from '../Dashboard/Features/Features';
import * as data from '../../data/data';
import CourseSection from '../Dashboard/CourseSection/CourseSection';
import Enrollment from '../Dashboard/Enrollment/Enrollment';
import StudentLife from '../Dashboard/StudentLife/StudentLife';
import OurTeam from '../Dashboard/OurTeam/OurTeam';
import ImgGallery from '../Dashboard/Gallery/Gallery';
import Footer from '../Footer/Footer';
import Space from '../../Components/Space/Space';
import TopBar from '../../Components/TopBar/TopBar';
import NewsStories from '../../Components/NewsStories/NewsStories';
import AboutSection from '../../Components/AboutSection/AboutSection';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrganizationDetails } from '../../redux/actions/organizationInfo';
import { fetchEvents } from '../../redux/actions/Events';
import { fetchBanners } from '../../redux/actions/Banners';
import { fetchFacilities } from '../../redux/actions/Facilities';
import { fetchCourses } from '../../redux/actions/Courses';
import { fetchTeams } from '../../redux/actions/Teams';
import { fetchGalleries } from '../../redux/actions/Galleries';
import { fetchStudentVoice } from '../../redux/actions/StudentVoice';
import { fetchNews } from '../../redux/actions/News';
import ReactPlayer from 'react-player';
import { fetchAdvertisements } from '../../redux/actions/Advertisement';

import AdComponent from '../../Components/Advertisement/Advertisement';


const Home = (props) => {
    let history = useHistory();
    const [popup, setPopup] = useState(false)

    const banners = useSelector(state => state.Banners);
    const events = useSelector(state => state.Events);
    const Organization = useSelector(state => state.Organization);
    const Facilities = useSelector(state => state.Facilities);
    const Course = useSelector(state => state.Course);
    const Teams = useSelector(state => state.Teams);
    const News = useSelector(state => state.News);
    const Galleries = useSelector(state => state.Galleries);
    const Student = useSelector(state => state.Student);
    const Advertisement = useSelector(state => state.Advertisements);
    const dispatch = useDispatch()
    const [images, setImages] = useState([]);
    const [vPlay, setVPlay] = useState(false);


    useEffect(() => {
        dispatch(fetchOrganizationDetails())
        dispatch(fetchBanners('status=true'))
        dispatch(fetchEvents('status=true&_sort=eventDate:desc&_limit=5&category_ne=Annual'))
        dispatch(fetchFacilities('status=true&_limit=6'))
        dispatch(fetchCourses('status=true&_limit=6'))
        dispatch(fetchTeams('status=true&_limit=20'))
        dispatch(fetchGalleries('status=true&_limit=6'))
        dispatch(fetchStudentVoice('status=true&_limit=6'))
        dispatch(fetchNews('status=true&_limit=4'))
        dispatch(fetchAdvertisements(`status=true&_limit=4&_sort=expireOn:asc&expireOn_gt=${(new Date().toISOString())}`))

    }, [0])

    useEffect(() => {
        if (Advertisement.advertisements.length > 0) {
            setPopup(true);
        }
    }, [Advertisement])

    useEffect(() => {
        let tempGal = [];
        Galleries.galleries && Galleries.galleries.map((gal, index) => {
            tempGal = [...tempGal, ...gal.images];
        })
        setImages(tempGal)
    }, [Galleries])

    return (
        <React.Fragment>
            <Modal
                show={popup}
                onHide={() => setPopup(false)}
                // backdrop="static"
                keyboard={false}
                size="lg"
            >
                <>
                    {Advertisement.advertisements && Advertisement.advertisements.length > 0 &&
                        <>
                            <Modal.Header closeButton>
                                <Modal.Title>{Advertisement.advertisements[0].title}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <AdComponent data={Advertisement.advertisements[0]}></AdComponent>
                            </Modal.Body>
                        </>
                    }
                </>


            </Modal>
            <div style={{ position: 'relative' }}>
                <TopBar data={News.news} />
                <Slider data={banners.banners} loading={banners.fetching} />
            </div>
            <EventSection data={events.events} loading={events.fetching} />
            <Space size={60} />

            {/* <Features data={data.Facilities}/> */}
            {/* <Space size={100} /> */}


            {/* <Enrollment/> */}
            {/* <Space size={100} /> */}


            <CourseSection data={Course.courses} loading={Course.fetching} />
            <Space size={100} />

            <AboutSection onClick={() => setVPlay(true)} data={Organization.info} loading={Organization.fetching} />
            <Space size={100} />




            <OurTeam data={Teams.teams} loading={Teams.loading} />

            <Space size={100} />
            <StudentLife data={Student.voices} loading={Student.fetching} />
            <Space size={100} />
            <NewsStories data={News.news} loading={News.fetching} />

            <ImgGallery data={images} loading={Galleries.fetching} />
            <Space size={10} />

            <Modal
                show={vPlay}
                className="videoModal"
                onHide={() => { setVPlay(false) }}
            >
                <Modal.Body>
                    <ReactPlayer
                        url={Organization && Organization.info && Organization.info.coverVideo}
                        stopOnUnmount
                        playing
                        controls
                    />
                </Modal.Body>
            </Modal>

        </React.Fragment>
    )
}

export default Home;