import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ResearchPublications.css';
import { baseUrl } from '../../api/api';
import { getImageUrl } from '../../utils/ImageUrl';
import { Container } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';

const ResearchPublications = (props) => {

    const [publications, setPublications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const location = useLocation();
    const history = useHistory();

    // Get current page from URL or default to 1
    const searchParams = new URLSearchParams(location.search);
    const currentPage = parseInt(searchParams.get('page')) || 1;
    const pageSize = 9;
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const fetchPublications = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${baseUrl}/research-publications`, {
                    params: {
                        _start: (currentPage - 1) * pageSize,
                        _limit: pageSize,
                        ...(props.slug != 'research-publication' ? { type_eq: props.slug === 'internal-publication' ? 'INTERNAL' : 'EXTERNAL' } : {})
                    }
                });

                const responseCount = await axios.get(`${baseUrl}/research-publications/count`);

                setTotalCount(responseCount.data);

                setPublications(response.data);
                setTotalPages(Math.ceil(responseCount.data / pageSize));
                setError(null);
            } catch (err) {
                setError('Failed to fetch publications. Please try again later.');
                console.error('Error fetching publications:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchPublications();
    }, [currentPage, props.slug]);

    const handlePageChange = (newPage) => {
        history.push(`?page=${newPage}`);
    };

    if (loading) {
        return <div>Loading publications...</div>;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <Container>
            <div className="research-publications">
                <div className="publications-grid">
                    {publications.map((publication) => (
                        <div key={publication.id} className="publication-card">
                            {publication.coverImage && (
                                <div className="publication-image">
                                    <img
                                        src={getImageUrl(publication.coverImage?.url)}
                                        alt={publication.title}
                                    />
                                </div>
                            )}
                            <h2>{publication.title}</h2>
                            {/* <p className="type">{publication.type}</p> */}
                            {publication.url && (
                                <a
                                    href={publication.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="publication-link"
                                >
                                    View Publication
                                </a>
                            )}
                        </div>
                    ))}
                </div>

                {totalPages > 1 && (
                    <div className="pagination">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="pagination-button"
                        >
                            Previous
                        </button>

                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)}
                                className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                            >
                                {index + 1}
                            </button>
                        ))}

                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="pagination-button"
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </Container>
    );
};

export default ResearchPublications;
